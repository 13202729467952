<template>
  <!-- <v-row class="my-4"> -->
    <div class="d-flex align-center" style="width:100%; flex-direction: column; height: 100%">
<!--        <v-img-->
<!--                class="mx-4 my-2"-->
<!--                style="border-radius:20px"-->
<!--                :height="isMobile? '150':'200'"-->
<!--                :src="isMobile?`${imgHost}imgs/category/${foundProduct.product_type.slug}.png?w=300`:-->
<!--                   `${imgHost}imgs/category/${foundProduct.product_type.slug}.png`"-->
<!--                :lazy-src="`${imgHost}imgs/category/${foundProduct.product_type.slug}.png?w=20`"-->
<!--        >-->
<!--                <template v-slot:placeholder>-->
<!--                    <v-row-->
<!--                            class="fill-height ma-0"-->
<!--                            align="center"-->
<!--                            justify="center"-->
<!--                    >-->
<!--                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>-->
<!--                    </v-row>-->
<!--                </template>-->
<!--            <v-layout-->
<!--                    id="akoid"-->
<!--                    column-->
<!--                    justify-center-->
<!--                    width="800px"-->
<!--            >-->
<!--                <h1 class="mb-3 primary&#45;&#45;text"-->
<!--                    style="text-shadow: 1px 1px rgba(0,0, 0, 0.2)">{{foundProduct.product_type.name}}</h1>-->
<!--            </v-layout>-->
<!--        </v-img>-->

            <mini-found-product v-if="isMobile" :prodSet="foundProduct"></mini-found-product>
            <found-product v-else :prodSet="foundProduct"></found-product>
            <new-product-card :prodSet="foundProduct"></new-product-card>

<!--        <mini-chip-filter v-if="isMobile"  class="mt-4"></mini-chip-filter>-->
<!--        <chip-filter v-else class="mt-4"></chip-filter>-->
        <suggestions-card></suggestions-card>
    </div>
  <!-- </v-row> -->
</template>
<script>
  import MiniFoundProduct from "../../../components/Website/Product/MiniFoundProduct"
  import FoundProduct from "../../../components/Website/Product/FoundProduct"
  import SuggestionsCard from "../../../components/Website/Category/SuggestionsCard"
  import ChipFilter from "../../../components/Website/Category/ChipFilter"
  import MiniChipFilter from "../../../components/Website/Category/MiniChipFilter"
import NewProductCard from "../../../components/Website/Product/NewProductCard.vue"
  export default {
    name:'SearchLanding',
    data() {
      return {
        expanded: true,
        rightDrawer: false,
        featured_models:[],
          grade: [
          {id:0,text:''},
          {id:1,text:'Large'},
          {id:2,text:'Small'},
        ],
        pack_size: [
          {id:0,text:''},
          { id:1,text: 'Cartons' },
          { id:2,text: 'Crates' },
          { id:3,text: 'Boxes' },

        ],
        egg_type: [
          {id:0,text:''},
          { id:1, text: 'Quail' },
          { id:2, text: 'Chicken Eggs' },
        ],
        egg_color:[
          {id:0,text:''},
          {id:1, text:'Brown'},
          {id:2, text:'White'},
        ],
        best_match: [
          {id:0,text:''},
          { text: 'Top Selling' },
          { text: 'Newest' },
          { text: 'Oldest' },
          { text: 'Lower price' },
          { text: 'Higher price' }
        ],
        toggle_exclusive: 2,
        toggle_multiple: [1, 2, 3]
      }
    },
    computed:{
        foundProduct(){
            return this.$store.getters.found_product;
        },
        mainProduct(){
            return this.found_product.getCategories.find(e=>{
                return e.id === this.$route.params.id;
            })
        },
      catDetails(){
       return this.$store.getters.getCategories.find(e=>{
         return e.slug === this.mainProduct.product_type.slug;
       })
      },
    },
    mounted(){
        this.getProducts();
    },
    methods:{
        getProducts(){
            this.$store.dispatch('getProducts').then(res=>{
                this.found_product = res.data.products;
            });
        },
    },
    components:{
    SuggestionsCard,
    ChipFilter,
    MiniChipFilter,
    FoundProduct,
    MiniFoundProduct,
    NewProductCard
},
  }
</script>

<style lang="scss" scoped>
  .filter{
    background: #fff;
    border-radius: 32px;
  }
  .porto{
    background:rgba(255,255,255,0.6)
  }
  .dud{
    color: #fff;
    font-size: 90px;
    font-weight:bold;
    line-height: 1em;
    margin: 0;
    text-align: left;
    transform: translateY(-50%);
    width: 100%;
  }
  #akoid{
      border:1px solid #e3e3e3;
      width:500px;
      padding:20px;
      margin:20px;
      border-radius:15px;
      background:#ffffffaa;
  }

</style>
