<template>
    <div class="text-center">
        <v-container>
            <v-toolbar xs-10 offset-xs1 dense flat class="mx-1 mb-2 mt-4 transparent">
                <v-text-field
                        placeholder="Search ... "
                        outlined
                        dense
                        class="mt-7 ml-2"
                        v-model="search"
                ></v-text-field>
                <v-btn outlined rounded class="red darken-1 ml-10 elevation-8" dark
                ><v-icon left>mdi-magnify</v-icon> Search</v-btn>
            </v-toolbar>
            <v-chip
                    v-for="chip in chipSet"
                    :key="chip.id"
                    class="ma-2"
                    color="primary"
                    :outlined="chip.outlined"
                    :dark="!chip.outlined"
                    @click=""
            >
                <v-avatar class="mr-2">
                    <v-icon>mdi-filter-variant</v-icon>
                </v-avatar>
                {{chip.name}}
            </v-chip>
        </v-container>

    </div>
</template>
<script>
    export default {
        name:"ChipFilter",
        data(){
            return{
                search:"",
                chipSet:[

                ],
            }
        },
        computed:{
            chipSel(){

            },
        },
        mounted(){
            this.getProducts();
        },
        methods: {
            close () {
                alert('Chip close clicked')
            },
            getProducts(){
                this.$store.dispatch('getProducts').then(res=>{
                    let catSet =  res.data.products.filter(e=>{
                        return e.product_type.slug === this.$route.params.slug;
                    });
                    console.log({catSet:catSet})
                    this.chipSet = catSet[0].product_type.categories;
                });
            },
        },
    }
</script>

<style scoped>

</style>
