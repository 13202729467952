<template>
<!--  <v-row justify="center" class="my-4">-->
<!--      <middle-ware headline="Special Offers"></middle-ware>-->
<!--      <div v-for="(product, i) in product_details" :key="i" class=" col-6 col-sm-3 mx-0">-->
<!--          <mini-product-card v-if="isMobile" :product_details="product"> </mini-product-card>-->
<!--          <product-card v-else :product_details="product"> </product-card>-->
<!--      </div>-->
<!--  </v-row>-->
<!--    <div style="width: 90%" class="d-flex my-12" align-center>-->
<!--        <div style="width:100%;" class="my-4">-->
<!--            <middle-ware headline="Related Products"></middle-ware>-->
<!--&lt;!&ndash;          <v-row justify="center" class="my-n12">&ndash;&gt;-->
<!--&lt;!&ndash;            <v-col v-for="(product, i) in product_details" :key="i" cols="6" sm="3" md="3" xl="3" class="mx-0">&ndash;&gt;-->
<!--&lt;!&ndash;              <new-product-card v-if="i<=7" class="mt-8" :product_details="product"> </new-product-card>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;          </v-row>&ndash;&gt;-->
<!--            <div class="d-flex flex-wrap justify-center my-n6">-->
<!--                <v-col width="100%" cols="6" sm="4" md="3" align="center" v-for="(product, i) in product_details" :key="i" @click="" class="">-->
<!--                  <new-product-card v-if="i<=5" class="mt-8" :product_details="product"> </new-product-card>-->
<!--&lt;!&ndash;                    <mini-product-card class="mt-8" v-if="isMobile" :product_details="product"> </mini-product-card>&ndash;&gt;-->
<!--&lt;!&ndash;                    <product-card v-else :product_details="product"> </product-card>&ndash;&gt;-->
<!--                </v-col>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>
<script>import MiniProductCard from "../Product/MiniProductCard";
import ProductCard from "../Product/ProductCard";
import MiddleWare from "../MiddleWare"
import NewProductCard from "../Product/NewProductCard";

export default {
  name: "SuggestionsCard",
  data() {
    return {
        cat_products:[],
    };
  },
    mounted(){
      this.getProducts();
    },
    computed:{
      foundProduct(){
          return this.$store.getters.found_product;
      },
        product_details(){
            return this.cat_products.filter(e=>{
                return e.product_type.slug === this.foundProduct.product_type.slug;
            });
        },
    },
    methods:{
        getProducts(){
            this.$store.dispatch('getProducts').then(res=>{
                this.cat_products = res.data.products;
            });
        },
    },
  components: {
    ProductCard,
    MiniProductCard,
      MiddleWare,
    NewProductCard
  }
};
</script>

<style scoped lang="scss">

</style>
