<template>
    <div>
        <v-layout>
            <v-flex sm8 offset-md2>
                <v-card
                        style="border-radius:20px"
                        color="white"
                        elevation="0"
                >
                    <v-toolbar
                            flat
                            dense
                            class="transparent"
                    >
                    </v-toolbar>
                    <div class="row justify-center align-center mt-n12 pa-6" :class="{'flex-column-reverse' : isMobile}">
                        <div class="col-sm-8">
                            <h3 class="black--text">{{prodSet.name}}</h3>
                            <span class="primary--text font-weight-bold">@{{prodSet.price | currency}}<i class="font-weight-regular caption"></i>{{prodSet.weight && prodSet.units? ` / ${prodSet.weight} ${prodSet.units}`:``}}</span>
                            <p class="in-para">
                                {{prodSet.company.name}}
                            </p>
                            <h4 class="quick">Quick Overview:</h4>
                            <label class="grey white--text darken-2 pa-1" style="font-size: small; border-radius:5px">Minimun Order Quantity: <b class="">{{prodSet.minimum}}</b></label>
                            <table class="details_table my-2" style="font-size: small">
                                <tr v-if="prodSet.usage">
                                    <td class="black--text ml-2">Usage: <b>{{prodSet.usage}}</b></td>
                                    <td class="black--text ml-2"></td>
                                </tr>
                                <tr v-if="prodSet.weight">
                                    <td class="black--text ml-2">Weight: <b>{{prodSet.weight}}{{prodSet.units}}</b> </td>
                                    <td class="black--text ml-4"></td>
                                </tr>
                                <tr>
                                    <!--                        <td class="black&#45;&#45;text ml-2">Packaging Type: </td>-->
                                </tr>
                            </table>
                            <div class="price_single">
                                <v-layout>
                                    <v-flex sm3 class="mx-2">
                                        <v-text-field
                                                rounded
                                                outlined
                                                dense
                                                hint="Qty"
                                                label="Qty"
                                                persistent-hint
                                                v-model="quantity"
                                                type="number"
                                                aria-valuemin="1"
                                                step="1"
                                                min="1"
                                        >
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex sm4 class="mx-2">
                                        <v-text-field
                                                disabled
                                                color="red"
                                                rounded
                                                filled
                                                dense
                                                :hint="`Price(${currency})`"
                                                persistent-hint
                                                v-model="prodSet.price"
                                        >
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex sm4 class="mx-2">
                                        <v-text-field
                                                disabled
                                                color="red"
                                                rounded
                                                filled
                                                dense
                                                :hint="`Subtotal(${currency})`"
                                                persistent-hint
                                                v-model="computedPrice"
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="mt-2">
                                    <v-flex sm6>
                                        <v-btn
                                                color="primary"
                                                outlined
                                                rounded
                                                @click="addCart"
                                        >Add to Cart<v-icon right>mdi-cart</v-icon></v-btn>
                                    </v-flex>
                                    <v-flex sm6>
                                        <v-btn
                                                color="primary"
                                                rounded
                                                @click="goCheckout"
                                        >Checkout<v-icon right>mdi-cart-arrow-right</v-icon></v-btn>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </div>

                        <div class="col-sm-4" >
                            <v-img
                                    style="border-radius:20px"
                                    :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=400`"
                                    :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=20`"
                                    width="300px"
                                    height="200px"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </div>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {eventBus}  from "../../../main";
    export default {
        props:[
            'dialog',
            'prodSet'
        ],
        name: "FoundProduct",
        data(){
            return {
                quantity:1,

            }
        },
        computed:{
            modal_dialog(){
                return this.dialog;
            },
            computedPrice(){
                return parseFloat(this.quantity) * parseFloat(this.prodSet.price);
            },
        },
        methods:{
               addCart(){
                 let prodUp = {...this.prodSet,purchase_quantity: this.quantity, subtotal: this.computedPrice};
                   eventBus.$emit("cart_add", true);
                   this.$store.commit("setCartMenu",true);
                   this.$store.commit('setSnack', { color: 'green', status_msg: 'Success!', added_msg: 'Product Added to cart.' })
                   this.$store.commit("setCart",prodUp);
               },
            goCheckout(){
                let prodUp = {...this.prodSet,purchase_quantity: this.quantity, subtotal: this.computedPrice};
                this.$store.commit("setCartMenu",true);
                   this.$store.commit("setCart",prodUp);
                   this.$router.push("/cart");
               },
            closeup(){
                 eventBus.$emit("product_modal", false);
            }
        }

    }
</script>

<style scoped lang="scss">
    p.in-para{
        color: #B3B3B3;
        font-size: 1.2em;
        line-height: 1em;
        margin: 1em 0 ;
        border-bottom: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        padding:1em 0;
    }
    .price_single {
        padding-bottom: 0.3em;
    }
    span.reducedfrom {
        color: #FAB005;
        font-size: 1.5em;
    }

    h4.quick {
        color: #000;
        font-size:1.1em;
        text-transform: uppercase;
        margin-top: 0.2em;

    }
    p.quick_desc {
        color:#B3B3B3;
        font-size:0.9em;
        line-height: 1.4em;
        margin: 0.4em 0 1em;
    }

    /* Shutter In Vertical */
    .my-cart-btn1 {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        background-color:#FAB005;
        color: #fff;
        padding: 0.5em 1em;
        font-size:1em;
        text-decoration:none;
        border: none;
    }
    .my-cart-btn1:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background:#029241;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        border: none;
    }
    .my-cart-btn1:hover:before, .my-cart-btn1:focus:before, .my-cart-btn1:active:before {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        text-decoration:none;
    }


    ul.product-qty {
        padding: 0;
        list-style: none;
    }
    .product-qty span {
        color: #000;
        font-size:1.2em;
        padding-bottom: 0.5em;
        display: block;
        text-transform: uppercase;

    }
    .product-qty select {
        border: 1px solid #eee;
        padding: 0.5em;
        font-size: 1em;
        outline: none;
    }
    .product-qty option {
        border: 1px solid #EEE;
    }
    .wish-list {
        padding: 15px 0;
        border-bottom: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
    }
    .wish-list ul{
        padding:0;
        list-style:none;
    }
    .wish-list li {
        display: inline-block;
        margin-right: 4%;
        width: 36%;
    }
    .wish-list li a i{
        margin-right:4%;
    }
    .wish-list li a {
        color:#FAB005;
        font-size: 0.85em;
        text-decoration: underline;
    }
    .wish-list li a span {
        padding-right:10px;
    }
    .wish-list li a:hover{
        text-decoration:none;
    }

    .size-top {
        margin: 2em 0;
    }

</style>
