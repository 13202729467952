<template>
    <div>
            <v-row justify="center">
                <v-col cols="11" md="10">
                    <v-form @submit.prevent="emitSearch">
                        <v-text-field
                            v-model="search"
                            type="search"
                            placeholder="Search ... "
                            outlined
                            dense
                        >
                            <template v-slot:append-outer>
                                <v-btn color="red" type="submit" class="darken-1 search-btn" dark>
                                    <v-icon :left="!isMobile">mdi-magnify</v-icon>
                                    <span v-if="!isMobile">Search</span>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-form>
                </v-col>
            </v-row>
    </div>
</template>
<script>
    export default {
        name:"ChipFilter",
        data(){
            return{
                search:"",
            }
        },
        methods: {
            emitSearch(){
                this.$emit('search', this.search)
            },
        },
    }
</script>

<style scoped>
.search-btn{
    margin-top: -7px;
}
</style>
